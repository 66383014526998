import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '../../services/translate.service';
import { PlayerComponent } from '../player/player.component';
import { HelperService } from '../../services/helper.service';

@Component({
  selector: 'app-content',
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.scss']
})
export class ContentComponent implements OnInit, OnChanges {
  public activeContent;
  public layoutMode = 'default';
  @Input() content;
  constructor(
    public helper: HelperService,
    public translate: TranslateService
  ) { }

  ngOnChanges(changes:SimpleChanges) {
    if(changes['content']){
      this.content = changes['content'].currentValue;
      if(this.content && this.content.length > 0){
        this.layoutMode = 'tabs';
        this.activeContent = this.content[0];
      }else if(this.content){
        this.activeContent = this.content;
      }
    }
  }

  ngOnInit() {
  }
  tabClick(tab) {
    this.activeContent = tab;
  }

}
