import { Component, OnInit, Input, Output } from '@angular/core';
import { EventEmitter } from 'events';
import { HelperService } from '../../services/helper.service';


@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})

export class LoaderComponent implements OnInit {

  @Output() onLoad: EventEmitter;
  @Output() onStart: EventEmitter;
  @Output() onFinish: EventEmitter;


  constructor(public helper:HelperService) { }

  ngOnInit() {
  }

  onLoadEmit(evt){
    this.onLoad.emit(evt)
  }
  onStartEmit(evt){
    this.onStart.emit(evt)
  }
  onFinishEmit(evt){
    this.onFinish.emit(evt)
  }


}
