import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  private defaultLanguage:string = 'br';
  private currentLanguage:string;
  constructor() { }

  get(obj, prop){
    if(obj.translatedContent){
      obj = obj.translatedContent;
    }
    let lang = this.currentLanguage ? this.currentLanguage : this.defaultLanguage;
    if(!obj) return '';
    if(obj[prop + '_' + lang]) return obj[prop + '_' + lang];
    if(obj[prop + '_' + this.defaultLanguage]) return obj[prop + '_' + this.defaultLanguage];
    if(obj[prop]) return obj[prop];
  }
}
